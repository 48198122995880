import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const MembersKyoujyu = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Programer：きょうじゅ｜メンバー紹介｜About us｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-aboutusPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">
            About us
          </h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <Link to="/aboutus-data" className=''>
                データで見るSUP
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-environment" className=''>
                働く環境
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-members" className=''>
                メンバー紹介
              </Link>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section id="memberProfile" className="mb-20">
              <div className="flex flex-wrap justify-center sm:justify-start">

                <div className="flex w-full md:w-1/2 p-3">
                  <div className="w-1/3">
                    <StaticImage src="../../images/kyoujyu.png" className="w-full h-auto max-w-[160px]"/>
                  </div>
                  <div className="w-2/3 px-4">
                    <h3 className=" pl-2 my-3 border-l-4 border-key-sand">
                      開発チーム<br />プログラマー
                    </h3>
                    <h2 className="text-xl pl-3 mb-3 font-medium">きょうじゅ</h2>
                    <p className="text-xs pl-3">入社：2017年3月<br />出身：山形県</p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      現在の仕事内容は？
                    </p>
                    <p className="p-2">
                    業務webシステムの保守や<br />
                    モニタリングプラスの保守をしています。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      仕事のやりがいを感じるときは？
                    </p>
                    <p className="p-2">
                    きれいにコードを書けた時です。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      職場の雰囲気は？
                    </p>
                    <p className="p-2">
                    概ね静かだが雑談を拒む雰囲気では無いです。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      スタートアッププラスの良いところは？
                    </p>
                    <p className="p-2">
                    雰囲気の良さですね。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      前職について教えてください。
                    </p>
                    <p className="p-2">
                    中小IT(特定派遣)でした。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      会社を選ぶ際にこだわったことは？
                    </p>
                    <p className="p-2">
                    自社開発をしているか(実際は請負)です。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      入社を決めた要因を教えてください。
                    </p>
                    <p className="p-2">
                    ブランクのある状態かつweb系の経験が無い中、ウェルカムムードが複数社の中で一番強かったからです。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      入社して自分が一番成長したところは？
                    </p>
                    <p className="p-2">
                    少なくともPHPは書けるようになりました。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      仕事でこれからチャレンジしてみたいことは？
                    </p>
                    <p className="p-2">
                    文書化の徹底です。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      入社して驚いた事はありますか？
                    </p>
                    <p className="p-2">
                    代表の私物が多いことです。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      印象に残っている出来事はありますか？
                    </p>
                    <p className="p-2">
                    休憩室にあったゲームを誰もやらなかったことです。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      仕事とプライベートの両立はできていますか？
                    </p>
                    <p className="p-2">
                    できている気がします。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      「あってよかった」と感じる社内制度・福利厚生は？
                    </p>
                    <p className="p-2">
                    入社時の引っ越し費用を出してくれたのは助かりました。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      どんな人に入社してきてほしい？
                    </p>
                    <p className="p-2">
                    進んで誰かが教えてくれるわけではないので、自分から学ぶ姿勢は必要です。なんでもやってみるという姿勢があると大変素晴らしいと思いますし、それをみんなで共有できる(文書化できる)となお良いと思います。
                    </p>
                  </div>
                </div>
              </div>

            </section>

            <Link to="/aboutus-members" class=" m-5 flex justify-center border border-neutral-800 px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
              </svg>
              <span className=" ">メンバーの一覧に戻る</span>
            </Link>

          </div>
        </div>
      </div>

    </Layout>

    
  )
}



export default MembersKyoujyu

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

